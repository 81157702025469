<script lang="ts">
  import type { Locales } from "../i18n";
  import { toMoney } from "../scripts/money";
  import type { Currency } from "../scripts/types";

  export let currency: Currency;
  export let amount: number;
  export let currentLocale: Locales;

  const formatter = Intl.NumberFormat(currentLocale, {
    style: "currency",
    currency,
  });

  const money = toMoney(currency, amount);
  const parts = formatter.formatToParts(money);
</script>

<div class="text-6xl md:text-8xl font-semibold text-gray-600">
  {#each parts as part}
    {#if part.type === "decimal" || part.type === "fraction"}
      <span class="text-base md:text-xl text-gray-500">{part.value}</span>
    {:else if part.type === "currency"}
      <span class="text-4xl md:text-6xl">{part.value}</span>
    {:else}
      <span>{part.value}</span>
    {/if}
  {/each}
</div>
